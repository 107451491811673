<template>
  <div class="pa-4" style="width: 85%; max-width: 500px">
    <v-card class="rounded pa-2" :color="message.incoming ? '' : '#035d4d'">
      <v-img v-if="message.image" :src="message.image" class="rounded" />
      <div class="mt-2">
        <base-md-viewer :value="message.text" />
      </div>
    </v-card>
    <v-row v-if="message.buttons && message.buttons.length" class="mt-1">
      <v-col v-for="(btn, i) in message.buttons" :key="i" class="pb-0 pt-1">
        <v-btn block class="primary--text">{{ btn }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  computed: {
    imageBg() {
      if (this.$vuetify.theme.isDark)
        return require("@/assets/images/wa-bg-dark.jpg");
      else return require("@/assets/images/wa-bg-light.jpg");
    },
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

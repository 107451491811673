<template>
  <div>
    <v-card-text>
      <v-stepper v-model="step" vertical>
        <v-stepper-step :complete="step > 1" step="1"> Regras </v-stepper-step>

        <v-stepper-content step="1">
          <v-alert text border="left" type="info">
            <b>Comprou e Ganhou</b>: Nesse tipo de promoção, o cliente que
            comprar ingresso de um dos lotes selecionados, ganha um ingresso de
            outro lote selecionado.
          </v-alert>
          <label class="text-16 font-weight-bold">
            Selecione os lotes que cliente deve comprar
          </label>
          <v-data-table
            v-model="data.bought"
            item-key="id"
            :headers="headers"
            :items="ticketBlocks"
            group-by="ticketGroup.party.name"
            show-select
            single-expand
          >
            <template
              v-slot:group.header="{ group, toggle, isOpen, headers, isMobile }"
            >
              <td :colspan="isMobile ? 1 : headers.length">
                <div class="d-flex align-center">
                  <v-btn @click="toggle(group)" class="mr-2 flex-shrink-1" icon>
                    <v-icon v-if="isOpen">mdi-chevron-down</v-icon>
                    <v-icon v-else>mdi-chevron-right</v-icon>
                  </v-btn>
                  <h6 class="mb-0">{{ group }}</h6>
                </div>
              </td>
            </template>
            <template v-slot:item.data-table-select="{ isSelected, select }">
              <v-checkbox :input-value="isSelected" @change="select" />
            </template>
            <template v-slot:item.price="{ item }">
              {{ item.price | currency }}
            </template>
          </v-data-table>
          <v-divider class="my-4" />
          <label>Selecione o lote que o cliente ganhará</label>
          <v-data-table
            v-model="data.won"
            item-key="id"
            :headers="headers"
            :items="ticketBlocks"
            group-by="ticketGroup.party.name"
            show-select
            single-select
          >
            <template
              v-slot:group.header="{ group, toggle, isOpen, headers, isMobile }"
            >
              <td :colspan="isMobile ? 1 : headers.length">
                <div class="d-flex align-center">
                  <v-btn @click="toggle(group)" class="mr-2 flex-shrink-1" icon>
                    <v-icon v-if="isOpen">mdi-chevron-down</v-icon>
                    <v-icon v-else>mdi-chevron-right</v-icon>
                  </v-btn>
                  <h6 class="mb-0">{{ group }}</h6>
                </div>
              </td>
            </template>
            <template
              v-slot:item.data-table-select="{ isSelected, select, item }"
            >
              <v-checkbox
                :input-value="isSelected"
                @change="select"
                :disabled="data.bought.some((i) => i.id == item.id)"
              />
            </template>
            <template v-slot:item.price="{ item }">
              {{ item.price | currency }}
            </template>
          </v-data-table>
        </v-stepper-content>

        <v-stepper-step :complete="step > 2" step="2">
          Mensagens
        </v-stepper-step>
        <v-stepper-content step="2">
          <wa-view :messages="message" />
          <v-form v-model="validMessages" @submit="() => {}">
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  outlined
                  v-model="data.message.text"
                  label="Mensagem"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  required
                  prefix="Olá Usuário,"
                />
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  outlined
                  v-model="data.message.buttons.accept"
                  label="Aceitar"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  required
                />
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  outlined
                  v-model="data.message.buttons.reject"
                  label="Recusar"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  required
                />
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>
        <v-stepper-step :complete="step > 3" step="3"> Resumo </v-stepper-step>
        <v-stepper-content step="3">
          <v-row align="center" justify="center">
            <v-col cols="12" sm="">
              <v-card>
                <v-card-title class="justify-center">
                  <v-icon left>mdi-ticket</v-icon>
                  <span class="headline text-center">
                    Comprando um desses lotes
                  </span>
                </v-card-title>
                <v-card-text>
                  <v-list>
                    <v-list-item
                      v-for="item in data.bought"
                      :key="item.id"
                      class="px-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.ticketGroup.party.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.ticketGroup.name }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-subtitle>
                          {{ item.price | currency }}
                        </v-list-item-subtitle>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="auto" class="text-center">
              <v-icon
                v-if="$vuetify.breakpoint.smAndUp"
                class="display-1 text-center"
              >
                mdi-arrow-right
              </v-icon>
              <v-icon v-else class="display-1 text-center">
                mdi-arrow-down
              </v-icon>
            </v-col>
            <v-col cols="12" sm="">
              <v-card>
                <v-card-title class="justify-center">
                  <v-icon left>mdi-ticket</v-icon>
                  <span class="headline text-center"
                    >O cliente ganha esse lote</span
                  >
                </v-card-title>
                <v-card-text>
                  <v-list>
                    <v-list-item
                      v-for="item in data.won"
                      :key="item.id"
                      class="px-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.ticketGroup.party.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.ticketGroup.name }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-subtitle>
                          {{ item.price | currency }}
                        </v-list-item-subtitle>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-alert type="warning" border="left" text dense>
            <b>Atenção!</b> Essa promoção não pode ser editada após ser criada.
            Verifique se todos os dados estão corretos. Apos a criação, a
            promoção já estará ativa.
          </v-alert>
        </v-stepper-content>
      </v-stepper>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="back" :disabled="loading"> Voltar </v-btn>
      <v-btn
        v-if="step < 3"
        color="primary"
        @click="next"
        :disabled="!currentIsValid"
      >
        Proximo
      </v-btn>
      <v-btn v-else color="primary" @click="save" :loading="loading">
        Salvar
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
import TICKET from "@/services/admin/ticket";

import { mapGetters } from "vuex";
import WaView from "../../global/WaView.vue";

export default {
  components: { WaView },
  data: () => ({
    loadingTicketBlocks: false,
    ticketBlocks: [],
    headers: [
      {
        text: "Setor",
        value: "ticketGroup.name",
      },
      {
        text: "Lote",
        value: "name",
      },
      {
        text: "Valor",
        value: "price",
      },
    ],
    step: 1,
    data: {
      bought: [],
      won: null,
      message: {
        text: "você recebeu uma cortesia, gostaria de aceitar?",
        buttons: {
          accept: "Aceitar",
          reject: "Não, obrigado",
        },
      },
    },
    validMessages: false,
  }),
  methods: {
    save() {
      this.$emit("save", {
        message: { ...this.data.message },
        bought: this.data.bought.map((i) => i.id),
        won: this.data.won[0].id,
      });
    },
    next() {
      if (this.currentIsValid) this.step++;
    },
    back() {
      if (this.step > 1) this.step--;
      else this.$emit("back");
    },
    async getTicketBlocks() {
      const orgId = this.selectedOrganization.id;
      this.loadingTicketBlocks = true;
      const { parties } = await ORGANIZATION.party.getAll(orgId);
      const ticketsGroups = [];
      for (const party of parties.filter((p) => p.active)) {
        const { ticketGroups: tg } = await TICKET.getTickets(orgId, party.id);
        ticketsGroups.push(...tg.map((t) => ({ ...t, party })));
      }

      this.ticketBlocks = ticketsGroups.reduce((acc, tg) => {
        const tbs = tg.TicketBlock.map((tb) => ({
          id: tb.id,
          name: tb.name,
          price: tb.price,
          ticketGroup: {
            id: tg.id,
            name: tg.name,
            party: tg.party,
          },
        }));
        return [...acc, ...tbs];
      }, []);
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    currentIsValid() {
      if (this.step == 1) {
        if (!this.data.won) return false;
        return this.data.bought.length > 0 && this.data.won.length == 1;
      } else if (this.step == 2) {
        return this.validMessages;
      } else return true;
    },
    message() {
      return [
        {
          incoming: true,
          image: this.data.won?.[0]?.ticketGroup.party.cover,
          text: `Olá Usuário, ${this.data.message.text} _(Prazo para resgate: 24h)_`,
          buttons: [
            this.data.message.buttons.accept,
            this.data.message.buttons.reject,
          ],
        },
      ];
    },
  },
  watch: {
    "data.bought": {
      handler(val) {
        if (val.some((v) => v.id == this.data.won?.[0]?.id)) this.data.won = [];
      },
      deep: true,
    },
  },
  created() {
    this.getTicketBlocks();
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>

<template>
  <v-dialog v-model="dialog" width="800" scrollable content-class="rounded-xl">
    <v-card :loading="loading" class="rounded-xl">
      <v-card-text class="pa-2">
        <v-alert v-if="error" type="error" text>
          {{ error }}
        </v-alert>

        <v-row class="mx-0">
          <v-col cols="12" sm="6" md="7" order-sm="first" order="last">
            <div class="d-flex flex-column gap-2" style="height: 100%">
              <div class="d-flex flex-column">
                <span class="text-overline" style="line-height: 0.5rem">{{
                  item.type === "PRODUCT" ? "Produto" : "Serviço"
                }}</span>
                <h4 class="mb-0">{{ item.name }}</h4>
                <p class="mb-0 pl-2">{{ item.description }}</p>
              </div>
              <p class="mb-0 font-weight-bold">Fornecedores</p>
              <vue-perfect-scrollbar
                :settings="{ suppressScrollX: true, wheelPropagation: false }"
                style="height: 100%"
                class="d-flex flex-column gap-2"
              >
                <supplier-card
                  v-for="(supplier, i) in suppliers"
                  :key="i"
                  :supplier="supplier"
                  @click="supplierModal(supplier)"
                />
                <v-card
                  v-if="!suppliers.length && !loading"
                  class="rounded-lg pa-4 d-flex align-center justify-center"
                  elevation="0"
                  outlined
                >
                  Ainda não temos fornecedores para este item
                </v-card>
              </vue-perfect-scrollbar>

              <div class="flex-grow-0">
                <v-btn text small @click="close"> Fechar </v-btn>
                <v-spacer></v-spacer>
              </div>
            </div>
          </v-col>
          <v-col cols="" align-self="center">
            <v-img class="rounded-xl" :src="item.image" :aspect-ratio="1" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CATALOG from "@/services/admin/extras/catalog";
import SupplierCard from "../SupplierCard.vue";
export default {
  components: {  SupplierCard },
  data: () => ({
    dialog: false,
    loading: false,
    error: false,
    item: {},
    suppliers: [],
  }),

  methods: {
    supplierModal(supplier) {
      this.close();
      const { Supplier, ...itemSupplier } = supplier;

      this.$root.$emit("catalog-supplier", {
        supplier: Supplier,
        item: {
          ...itemSupplier,
          Item: this.item,
        },
      });
    },
    async getItemDetails() {
      try {
        this.loading = true;
        this.error = false;
        const {
          item: { Suppliers, ...item },
        } = await CATALOG.item.getById(this.item.id);
        this.item = item;
        this.suppliers = Suppliers;
      } catch (e) {
        this.error = e.message || "Erro ao carregar detalhes do item";
      } finally {
        this.loading = false;
      }
    },
    open(item) {
      this.item = Object.assign({}, item);
      this.suppliers = [];
      this.getItemDetails();
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.item = {};
      this.suppliers = [];
      this.loading = false;
      this.error = false;
    },
  },
  mounted() {
    this.$root.$on("catalog-item", this.open);
  },
};
</script>

<style></style>

import http from "../../../http-common";
const getAllItens = async () => {
  return await http
    .get("/admin/extras/catalog")
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const getItemById = async (id) => {
  return await http
    .get(`/admin/extras/catalog/item/${id}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getSupplierById = async (id) => {
  return await http
    .get(`/admin/extras/catalog/supplier/${id}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getAllSuppliers = async () => {
  return await http
    .get(`/admin/extras/catalog/supplier`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const registerInterest = async (data) => {
  return await http
    .post(`/admin/extras/catalog/interest`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  item: {
    getAll: getAllItens,
    getById: getItemById,
  },
  supplier: {
    getById: getSupplierById,
    getAll: getAllSuppliers,
  },
  interest: {
    register: registerInterest,
  },
};

<template>
  <div>
    <h4 class="mt-2">Simulador de Custos</h4>
    <v-row>
      <v-col cols="12" sm="7" lg="8">
        <h6 class="px-2">
          Quanto você quer {{ type === 0 ? "cobrar" : "receber" }}?
        </h6>
        <v-card rounded="lg">
          <v-tabs grow v-model="type">
            <v-tab>Cobrar</v-tab>
            <v-tab>Receber</v-tab>
          </v-tabs>
          <div class="pa-4">
            <v-text-field
              :label="
                type === 0
                  ? 'Valor que deseja cobrar'
                  : 'Valor que deseja receber'
              "
              type="number"
              v-model="amount"
              prefix="R$"
              hide-details
              outlined
              large
            />
          </div>
        </v-card>
        <h6 class="mt-8 px-2">Como o cliente vai pagar?</h6>
        <v-card rounded="lg">
          <v-tabs grow v-model="paymentType">
            <v-tab tab-value="online">Online</v-tab>
            <v-tab tab-value="offline">Presencial</v-tab>
          </v-tabs>
          <div v-if="paymentType === 'online'" class="pa-4">
            <p class="text-center">
              Para pagamentos online, você paga a taxa da plataforma mais a taxa
              de processamento online do Mercado Pago
            </p>
            <h6 class="text-center">Taxas do Mercado Pago</h6>
            <div class="d-flex gap-2">
              <v-text-field
                class="flex-grow-1"
                outlined
                dense
                v-model="onlineTax.card"
                label="Taxa cartão"
                placeholder="4,98% - Padrão recebimento na hora"
                hide-details
                type="number"
                min="0"
                max="10"
                step="0.01"
              />
              <v-text-field
                class="flex-grow-1"
                outlined
                dense
                v-model="onlineTax.pix"
                label="Taxa Pix"
                placeholder="0,99% - Padrão"
                hide-details
                type="number"
                min="0"
                max="10"
                step="0.01"
              />
            </div>
            <p class="mt-4 mb-0 text-center">
              Defina a proporção de pagamentos via cartão e Pix para calcular a
              média da taxa de processamento online.
            </p>
            <div class="d-flex align-center gap-2">
              <span class="text-center lh-1">
                Cartão<br />
                <small>{{ 100 - onlineTax.percentage }}%</small>
              </span>
              <v-slider
                v-model="onlineTax.percentage"
                min="0"
                max="100"
                hide-details
              />
              <span class="text-center lh-1">
                Pix<br />
                <small>{{ onlineTax.percentage }}%</small>
              </span>
            </div>
          </div>
          <div
            v-else-if="paymentType === 'offline'"
            class="pa-4 d-flex flex-column align-center"
          >
            <p class="text-center">
              Em vendas presenciais com promotores, é cobrada apenas a taxa da
              plataforma.
            </p>
            <v-skeleton-loader
              class="text-center"
              width="40px"
              height="28px"
              v-if="loading"
              type="image"
            />
            <h5 v-else class="text-center mb-0">{{ ticketFee }}%</h5>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="5" lg="4">
        <v-card
          rounded="lg"
          class="pa-5 text-18 d-flex flex-column gap-6 mt-8 mb-4"
        >
          <p
            class="d-flex justify-space-between align-center lh-1 font-weight-bold mb-0 gap-4"
          >
            {{ type === 0 ? "O cliente paga" : "Para você receber" }}
            <span class="flex-shrink-0">
              {{ amountValue | currency }}
            </span>
          </p>

          <div
            v-for="(tax, i) in taxes"
            :key="i"
            class="d-flex justify-space-between mb-0 align-center text-16 gap-2 lh-1"
          >
            <div>
              <p class="mb-1 lh-1 font-weight-medium">
                {{ taxTypes[tax.type].title }}
              </p>
              <small>
                <v-skeleton-loader
                  class="text-center mt-2"
                  width="40px"
                  height="14px"
                  v-if="loading && tax.type === 'platform'"
                  type="image"
                />
                <small
                  v-else-if="tax.type === 'platform' && !fees"
                  class="error--text text-12"
                >
                  Não foi possível carregar a taxa
                </small>
                <span v-else>
                  {{ tax.percentage.toFixed(2) }}%

                  {{
                    taxTypes[tax.type].label
                      ? ` • ${taxTypes[tax.type].label}`
                      : ""
                  }}
                </span>
              </small>
            </div>
            <v-skeleton-loader
              class="text-center"
              width="55px"
              height="18px"
              v-if="fakeLoading || (loading && tax.type === 'platform')"
              type="image"
            />
            <span v-else class="flex-shrink-0 font-weight-medium">
              {{ tax.value | currency }}
            </span>
          </div>
          <p
            class="d-flex justify-space-between font-weight-bold mb-0 lh-1 align-center gap-4"
          >
            {{ type === 0 ? "Você recebe" : "O cliente deve pagar" }}
            <v-skeleton-loader
              class="text-center"
              width="75px"
              height="18px"
              v-if="loading || fakeLoading"
              type="image"
            />
            <span v-else class="flex-shrink-0">
              {{ finalValue | currency }}
            </span>
          </p>
        </v-card>
        <p
          class="text-center text--secondary px-3"
          v-if="paymentType === 'online'"
        >
          A taxa da plataforma é aplicada a cada transação. O total recebido
          varia conforme o método de pagamento selecionado pelo cliente.
        </p>
        <p
          class="text-center text--secondary px-3"
          v-else-if="paymentType === 'offline'"
        >
          As taxas de vendas presenciais serão acumuladas para pagamento futuro.
          Verifique o valor pendente na seção
          <router-link :to="{ name: 'admin.profile' }">
            Perfil da organização </router-link
          >.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";

export default {
  metaInfo: {
    title: "Simulador de Custos",
  },
  name: "CostSimulator",
  data: () => ({
    error: null,
    fees: null,
    loading: true,
    fakeLoading: false,
    // Variables
    type: 0,
    paymentType: 0,
    amount: 0,
    onlineTax: {
      pix: 0.99,
      card: 4.98,
      percentage: 50,
    },

    taxTypes: {
      platform: { title: "Taxa da plataforma" },
      online: { title: "Taxa processamento online", label: "Mercado Pago" },
    },
    interval: null,
  }),
  methods: {
    calculateTax(tax) {
      const amount = this.amountValue;
      const percentage = tax.percentage / 100;

      if (this.type === 0) {
        return -amount * percentage;
      } else {
        return amount / (1 - percentage) - amount;
      }
    },
    async getFees() {
      try {
        this.loading = true;
        this.error = false;
        const { fees } = await ORGANIZATION.fees(this.selectedOrganization.id);
        this.fees = fees;
      } catch (e) {
        this.error = e.message || "Erro ao carregar taxas";
      } finally {
        this.loading = false;
      }
    },
    fakeLoadingStart() {
      clearInterval(this.interval);
      this.fakeLoading = true;
      this.interval = setInterval(() => {
        this.fakeLoading = false;
        clearInterval(this.interval);
      }, 300);
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ticketFee() {
      if (!this.fees) return 0;
      return this.fees.reduce((acc, { type, value }) => {
        if (type === "TICKET") acc = Math.min(acc, value);
        return acc;
      }, Infinity);
    },
    amountValue() {
      return parseFloat(this.amount);
    },
    taxes() {
      const fees = [
        {
          type: "platform",
          percentage: this.ticketFee,
        },
      ];
      if (this.paymentType === "online") {
        const paymentPercentage = this.onlineTax.percentage / 100;
        const onlineTax =
          this.onlineTax.pix * paymentPercentage +
          this.onlineTax.card * (1 - paymentPercentage);
        fees.push({
          type: "online",
          percentage: onlineTax,
        });
      }
      return fees.map((fee) => ({
        ...fee,
        value: this.calculateTax(fee),
      }));
    },
    finalValue() {
      const amount = this.amountValue;
      const totalTax = this.taxes.reduce((acc, { value }) => acc + value, 0);
      return amount + totalTax;
    },
  },
  watch: {
    "selectedOrganization.id"() {
      this.fees = null;
      this.getFees();
    },
    amountValue(value) {
      if (value < 0) this.amount = 0;
      if (value) this.fakeLoadingStart();
    },
    onlineTax: {
      handler() {
        if (this.amountValue) this.fakeLoadingStart();
      },
      deep: true,
    },
  },
  mounted() {
    this.getFees();
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <v-card-text>
      <v-stepper v-model="step" vertical>
        <v-stepper-step :complete="step > 1" step="1"> Evento </v-stepper-step>
        <v-stepper-content step="1">
          <v-alert v-if="loadingParties" text type="info">
            Carregando Eventos
          </v-alert>
          <v-alert v-if="!parties.length && !loadingParties" text type="error">
            Nenhum evento ativo encontrado
          </v-alert>
          <v-row>
            <v-col
              v-for="(party, index) in parties"
              :key="index"
              cols="12"
              sm="4"
            >
              <v-card class="d-flex flex-column rounded-lg">
                <v-img
                  :src="party.cover"
                  @click="selectParty(party)"
                  :aspect-ratio="16 / 9"
                  class="pointer"
                />
                <v-card-text class="pa-3">
                  <h6>{{ party.name }}</h6>
                  <v-btn color="primary" @click="selectParty(party)" block>
                    Selecionar
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-step :complete="step > 2" step="2">
          Desconto
        </v-stepper-step>
        <v-stepper-content step="2">
          <div class="d-flex flex-wrap align-start py-1 gap-2">
            <v-btn-toggle
              v-model="data.discountType"
              class="mb-4 d-flex flex-grow-1"
              color="primary"
              mandatory
            >
              <v-btn small class="flex-grow-1" value="percentage">
                Porcentagem
              </v-btn>
              <v-btn small class="flex-grow-1" value="value">Valor</v-btn>
            </v-btn-toggle>
            <v-text-field
              v-model="data.discount"
              label="Desconto"
              :suffix="data.discountType == 'percentage' ? '%' : ''"
              :prefix="data.discountType == 'percentage' ? '' : 'R$'"
              dense
              outlined
              type="number"
              :step="data.discountType == 'percentage' ? 1 : 0.01"
              min="0"
              max="100"
              :rules="[(v) => !!v || 'Desconto é obrigatório']"
            />
          </div>
          <div class="d-flex flex-wrap align-start py-1 gap-2">
            <v-text-field
              v-model="data.startDate"
              label="Data Inicial"
              dense
              outlined
              type="date"
              :rules="[(v) => !!v || 'Data Inicial é obrigatória']"
            />
            <v-text-field
              v-model="data.endDate"
              label="Data Final"
              dense
              outlined
              type="date"
              :rules="[(v) => !!v || 'Data Final é obrigatória']"
            />
          </div>
          <label>Formas de Pagamento com Desconto</label>
          <div class="d-flex flex-wrap align-start gap-8 px-2 pb-2">
            <v-checkbox
              v-for="(pm, index) in paymentMethods"
              :key="index"
              v-model="data.paymentMethods"
              :label="pm.name"
              :value="pm.id"
              hide-details
              dense
            ></v-checkbox>
          </div>
        </v-stepper-content>

        <v-stepper-step :complete="step > 3" step="3"> Lotes </v-stepper-step>
        <v-stepper-content step="3">
          <v-row
            v-if="!loadingTicketBlocks"
            class="d-flex justify-center align-stretch mx-1"
          >
            <v-alert v-if="!ticketBlocks.length" text type="error">
              Nenhum lote encontrado
            </v-alert>
            <v-col
              cols="12"
              sm="6"
              md="4"
              v-for="(tb, index) in ticketBlocks"
              :key="index"
            >
              <v-card
                class="d-flex flex-column rounded-lg h-full"
                @click="toggleTb(tb)"
              >
                <v-card-text
                  class="pa-3 d-flex flex-column justify-space-between h-full"
                >
                  <div class="d-flex justify-space-between align-start">
                    <h6>{{ tb.ticketGroup.name }} - {{ tb.name }}</h6>

                    <v-btn icon @click.stop="toggleTb(tb)">
                      <v-icon>
                        {{
                          data.ticketBlocks.includes(tb.id)
                            ? "mdi-check-circle"
                            : "mdi-circle-outline"
                        }}
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="d-flex gap-2 align-center">
                    <h5
                      class="mb-0"
                      :class="{
                        'text--disabled': data.ticketBlocks.includes(tb.id),
                        'text-decoration-line-through':
                          data.ticketBlocks.includes(tb.id),
                      }"
                    >
                      {{ tb.price | currency(true) }}
                    </h5>
                    <v-chip
                      label
                      small
                      color="success"
                      :class="{
                        'text-decoration-line-through':
                          !data.ticketBlocks.includes(tb.id),
                      }"
                      :style="{
                        opacity: data.ticketBlocks.includes(tb.id) ? 1 : 0.4,
                      }"
                    >
                      <template v-if="data.discountType == 'percentage'">
                        {{
                          (tb.price * (1 - data.discount / 100))
                            | currency(true)
                        }}
                      </template>
                      <template v-else>
                        {{
                          Math.max(0, tb.price - data.discount) | currency(true)
                        }}
                      </template>
                    </v-chip>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-step :complete="step > 4" step="4"> Resumo </v-stepper-step>
        <v-stepper-content step="4">
          <div class="pa-2 d-flex flex-column gap-2">
            <!-- <v-card color="pa-2"> -->
            <h6 class="mb-0">
              <template v-if="data.discountType == 'percentage'">
                Desconto de {{ data.discount }}%
              </template>
              <template v-else>
                Desconto de R$ {{ data.discount | currency(true) }}
              </template>
              para compra de ingresso com pagamento
              {{ data.paymentMethods.join(" e ") }} entre
              {{ data.startDate | date("DD/MM/YY") }} e
              {{ data.endDate | date("DD/MM/YY") }}
            </h6>
            <!-- </v-card> -->

            <v-card
              v-for="(tb, index) in ticketBlocks.filter((tb) =>
                data.ticketBlocks.includes(tb.id)
              )"
              :key="index"
              outlined
              class="d-flex gap-1 align-center justify-space-between pa-3" 
            >
              <h6 class="mb-0">{{ tb.ticketGroup.name }} - {{ tb.name }}</h6>
              <div class="d-flex flex-wrap gap-2 text-end flex-grow-0 justify-end">
                <h5 class="mb-0 text--disabled text-decoration-line-through lh-1">
                  {{ tb.price | currency(true) }}
                </h5>
                <h5 class="mb-0 lh-1">
                  <template v-if="data.discountType == 'percentage'">
                    {{
                      (tb.price * (1 - data.discount / 100)) | currency(true)
                    }}
                  </template>
                  <template v-else>
                    {{ Math.max(0, tb.price - data.discount) | currency(true) }}
                  </template>
                </h5>
              </div>
            </v-card>
          </div>
        </v-stepper-content>
      </v-stepper>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="back" :disabled="loading"> Voltar </v-btn>
      <v-btn
        v-if="step < 4 && step > 1"
        color="primary"
        @click="next"
        :disabled="!currentIsValid"
      >
        Proximo
      </v-btn>
      <v-btn
        v-else-if="step > 1"
        color="primary"
        @click="save"
        :loading="loading"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
import TICKET from "@/services/admin/ticket";
import PartyStatus from "@/utils/partyStatus";
import moment from "moment";

import { mapGetters } from "vuex";
import WaView from "../../global/WaView.vue";

export default {
  components: { WaView },
  data: () => ({
    loadingParties: false,
    loadingTicketBlocks: false,
    ticketBlocks: [],
    parties: [],
    headers: [
      {
        text: "Setor",
        value: "ticketGroup.name",
      },
      {
        text: "Lote",
        value: "name",
      },
      {
        text: "Valor",
        value: "price",
      },
    ],
    step: 1,
    paymentMethods: [
      { id: "online", name: "Online" },
      { id: "offline", name: "Offline" },
    ],
    data: {
      partyId: null,
      discountType: "percentage",
      discount: 0,
      ticketBlocks: [],
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      paymentMethods: [],
    },
    validMessages: false,
  }),
  methods: {
    save() {
      this.$emit("save", {
        ...this.data,
        startDate: moment(this.data.startDate).format("YYYY-MM-DD"),
        endDate: moment(this.data.endDate).format("YYYY-MM-DD"),
      });
    },
    selectParty(party) {
      this.data.partyId = party.id;
      this.step++;
      this.getTicketBlocks(party);
    },
    next() {
      if (this.currentIsValid) this.step++;
    },
    back() {
      if (this.step > 1) this.step--;
      else this.$emit("back");
    },
    toggleTb(tb) {
      if (this.data.ticketBlocks.includes(tb.id)) {
        this.data.ticketBlocks = this.data.ticketBlocks.filter(
          (t) => t != tb.id
        );
      } else {
        this.data.ticketBlocks = [...this.data.ticketBlocks, tb.id];
      }
    },
    async getParties() {
      try {
        this.loadingParties = true;
        const orgId = this.selectedOrganization.id;
        const { parties } = await ORGANIZATION.party.getAll(orgId);
        this.parties = parties.filter((p) => {
          const status = new PartyStatus(p);
          return p.active && status.isActive;
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingParties = false;
      }
    },
    async getTicketBlocks(party) {
      try {
        const orgId = this.selectedOrganization.id;
        this.loadingTicketBlocks = true;
        const { ticketGroups } = await TICKET.getTickets(orgId, party.id);
        this.ticketBlocks = ticketGroups.reduce((acc, tg) => {
          const tbs = tg.TicketBlock.map((tb) => ({
            id: tb.id,
            name: tb.name,
            price: tb.price,
            ticketGroup: {
              id: tg.id,
              name: tg.name,
              party: party,
            },
          }));
          return [...acc, ...tbs];
        }, []);
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingTicketBlocks = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    currentIsValid() {
      if (this.step == 2) {
        if (this.data.discount <= 0) return false;
        if (!this.data.startDate || !this.data.endDate) return false;
        if (this.data.startDate > this.data.endDate) return false;
        if (this.data.paymentMethods.length == 0) return false;

        return true;
      } else if (this.step == 3) {
        return this.data.ticketBlocks.length > 0;
      } else return true;
    },
  },

  created() {
    this.getParties();
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>

<template>
  <v-card class="d-flex align-center pa-2 rounded-lg" elevation="0" outlined>
    <v-icon class="mr-2" size="50">mdi-ticket-percent</v-icon>
    <div class="flex-grow-1">
      <div class="d-flex align-center gap-1">
        <h5 class="mb-0">{{ cupom.name }}</h5>
        <v-spacer />
        <code class="pa-1 rounded" @click="copy" style="cursor: pointer">
          {{ cupom.code }}
          <v-icon small v-if="copied">mdi-check</v-icon>
          <v-icon small v-else>mdi-content-copy</v-icon>
        </code>
      </div>
      <small>{{ cupom.description }}</small>
    </div>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    copied: false,
  }),
  methods: {
    copy() {
      if (this.copied || !navigator.clipboard) return;
      navigator.clipboard.writeText(this.cupom.code);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
      this.$emit("copy");
    },
  },

  props: {
    cupom: {
      type: Object,
      default: () => ({}),
    },
    supplier: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>

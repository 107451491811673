<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="">
      <v-card>
        <v-card-title class="justify-center">
          <v-icon left>mdi-ticket</v-icon>
          <span class="headline text-center"> Comprando um desses lotes </span>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="item in promotion.bought"
              :key="item.id"
              class="px-0"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.TicketGroup.Party.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.TicketGroup.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-subtitle>
                  {{ item.price | currency }}
                </v-list-item-subtitle>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="auto" class="text-center">
      <v-icon v-if="$vuetify.breakpoint.smAndUp" class="display-1 text-center">
        mdi-arrow-right
      </v-icon>
      <v-icon v-else class="display-1 text-center"> mdi-arrow-down </v-icon>
    </v-col>
    <v-col cols="12" sm="">
      <v-card>
        <v-card-title class="justify-center">
          <v-icon left>mdi-ticket</v-icon>
          <span class="headline text-center">O cliente ganha esse lote</span>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item class="px-0">
              <v-list-item-content>
                <v-list-item-title>
                  {{ promotion.won.TicketGroup.Party.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ promotion.won.TicketGroup.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-subtitle>
                  {{ promotion.won.price | currency }}
                </v-list-item-subtitle>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" class="pb-0">
      <v-card>
        <div class="d-flex flex-wrap justify-space-around text-center">
          <div class="flex-column justify-center success--text px-2 py-1">
            <h6>{{ reduceCourtesyDelivery.accepted }}</h6>
            <span> Aceitos </span>
          </div>
          <div class="flex-column justify-center error--text px-2 py-1">
            <h6>{{ reduceCourtesyDelivery.rejected }}</h6>
            <span> Recusados </span>
          </div>
          <div class="flex-column justify-center warning--text px-2 py-1">
            <h6>{{ reduceCourtesyDelivery.pending }}</h6>
            <span> Aguardando resposta </span>
          </div>
          <div class="flex-column justify-center px-2 py-1">
            <h6>
              {{
                (
                  (reduceCourtesyDelivery.sent / reduceCourtesyDelivery.total ||
                    reduceCourtesyDelivery.sent) * 100
                ).toFixed(0)
              }}% <small>({{ reduceCourtesyDelivery.sent }})</small>
            </h6>
            <span> Enviados </span>
          </div>
        </div>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="promotion.courtesyDelivery"
        :search="search"
        :custom-filter="filterList"
        dense
      >
        <template v-slot:top>
          <div class="d-flex pa-2">
            <v-text-field
              outlined
              dense
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            />
          </div>
        </template>
        <template v-slot:item.phone="{ item }">
          <a
            :href="`http://wa.me/55${item.Ticket.Owner.phone.replace(
              /[^0-9]/g,
              ''
            )}?text=Olá, ${item.Ticket.Owner.name}!`"
            target="_blank"
            class="text-decoration-none text--black"
          >
            {{ item.Ticket.Owner.phone }}
          </a>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            v-if="item.response == null && item.messageId == null"
            color="gray"
            small
          >
            Pendente
          </v-chip>
          <v-chip
            v-if="item.response == null && item.messageId != null"
            color="warning"
            small
          >
            Aguardando
          </v-chip>
          <v-chip
            v-if="item.response != null"
            :color="item.response ? 'success' : 'error'"
            small
          >
            {{
              item.response
                ? "Aceito"
                : item.responseAt
                ? "Recusado"
                : "Expirado"
            }}
          </v-chip>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: false,
        value: "Ticket.Owner.name",
      },
      { text: "Whatsapp", value: "phone" },
      { text: "Status", value: "status" },
    ],
    search: "",
  }),
  methods: {
    filterName(search, { Ticket }) {
      if (!Ticket.Owner.name) return false;
      return Ticket.Owner.name.toLowerCase().includes(search.toLowerCase());
    },
    filterPhone(search, { Ticket }) {
      let p = Ticket.Owner.phone.replace(/\D/g, "");
      let s = search.replace(/\D/g, "");
      if (!p || !s) return false;
      return p.includes(s);
    },
    filterList(value, search, item) {
      return this.filterName(search, item) || this.filterPhone(search, item);
    },
  },
  computed: {
    reduceCourtesyDelivery() {
      return this.promotion.courtesyDelivery.reduce(
        (acc, cd) => {
          acc.total += 1;
          if (cd.messageId) {
            acc.sent += 1;
            if (cd.response != null) {
              if (cd.response) acc.accepted += 1;
              else acc.rejected += 1;
            } else acc.pending += 1;
          }
          return acc;
        },
        {
          accepted: 0,
          rejected: 0,
          pending: 0,
          sent: 0,
          total: 0,
        }
      );
    },
  },
  props: {
    promotion: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

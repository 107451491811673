<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="">
      <v-card class="d-flex gap-3 align-center rounded-lg">
        <div>
          <v-img
            :src="promotion.Party.cover"
            :aspect-ratio="16 / 9"
            width="120px"
            class="rounded-l-lg"
          />
        </div>
        <div>
          <span class="text-overline lh-1">{{
            promotion.Party.date | date("DD/MM/YYYY HH:mm")
          }}</span>
          <h6 class="mb-0">{{ promotion.Party.name }}</h6>
        </div>
      </v-card>
      <v-card class="mt-3 rounded-lg">
        <v-card-title class="justify-center">
          <v-icon left>mdi-ticket-percent </v-icon>
          <span class="text-center">
            Desconto de
            <v-chip
              color="success"
              label
              class="font-weight-bold"
              small
              v-if="promotion.data.discountType === 'percentage'"
            >
              {{ promotion.data.discount }}%
            </v-chip>
            <v-chip color="success" label class="font-weight-bold" small v-else>
              {{ promotion.discount | currency }}
            </v-chip>
          </span>
        </v-card-title>
        <div class="d-flex flex-wrap justify-space-around gap-x-4 gap-y-1">
          <div class="d-flex flex-column align-center">
            <p class="mb-0">
              <v-icon small left>mdi-calendar</v-icon>
              <span class="font-weight-bold">Válido de</span>
            </p>
            <span >
              {{ promotion.data.startDate | date("DD/MM/YYYY") }}
              a
              {{ promotion.data.endDate | date("DD/MM/YYYY") }}
            </span>
          </div>

          <div class="d-flex flex-column align-center gap-1">
            <p class="mb-0">
              <v-icon small left>mdi-cash</v-icon>
              <span class="font-weight-bold">Formas de pagamento</span>
            </p> <div class="d-flex gap-2">
              
              <v-chip
                v-for="pm in promotion.data.paymentMethods"
                :key="pm"
                label
                small
                color="primary"
              >
                {{ pm }}
              </v-chip>
            </div>
          </div>
        </div>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="item in promotion.ticketBlocks"
              :key="item.id"
              class="px-0"
            >
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ item.TicketGroup.name }}
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <span class="text--secondary text-decoration-line-through">
                  {{ item.price | currency }}
                </span>
                <h6
                  v-if="promotion.data.discountType === 'percentage'"
                  class="mb-0"
                >
                  {{
                    (item.price - item.price * (promotion.data.discount / 100))
                      | currency
                  }}
                </h6>
                <h6 v-else class="mb-0">
                  {{
                    Math.max(0, item.price - promotion.data.discount) | currency
                  }}
                </h6>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({}),
  methods: {},
  computed: {},
  props: {
    promotion: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>

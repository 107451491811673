<template>
  <div class="rounded" :style="{ background: `url(${imageBg})` }">
    <div class="d-flex flex-column">
      <wa-message-view
        v-for="(message, i) in messages"
        :key="i"
        :message="message"
        :class="message.incoming ? 'mr-auto' : 'ml-auto'"
      />
    </div>
  </div>
</template>

<script>
import WaMessageView from "./WaMessageView.vue";
export default {
  components: { WaMessageView },
  computed: {
    imageBg() {
      if (this.$vuetify.theme.isDark)
        return require("@/assets/images/wa-bg-dark.jpg");
      else return require("@/assets/images/wa-bg-light.jpg");
    },
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <base-card class="pb-2 rounded-lg">
      <v-tabs v-model="tab" show-arrows class="rounded-t-lg" grow>
        <v-tab v-for="tab in tabs" class="rounded-t-lg" :key="tab.component">
          <v-icon left small>{{ tab.icon }}</v-icon>
          {{ tab.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in tabs" :key="item.name">
          <component :is="item.component" class="px-4 pb-4" />
        </v-tab-item>
      </v-tabs-items>
    </base-card>
    <catalog-item-modal />
    <catalog-supplier-modal />
  </div>
</template>

<script>
import CatalogItems from "@/components/extras/catalog/CatalogItems.vue";
import CatalogSuppliers from "@/components/extras/catalog/CatalogSuppliers.vue";

import CatalogItemModal from "@/components/extras/catalog/modals/CatalogItemModal.vue";
import CatalogSupplierModal from "@/components/extras/catalog/modals/CatalogSupplierModal.vue";
export default {
  metaInfo() {
    return { title: `Fornecedores` };
  },
  components: { CatalogItems, CatalogItemModal, CatalogSupplierModal,CatalogSuppliers },
  data: () => ({
    loading: true,
    tab: null,
    tabs: [
      {
        id: "membership",
        name: "Produtos e Serviços",
        component: "CatalogItems",
        icon: "mdi-shopping",
      },
      {
        id: "membership",
        name: "Fornecedores",
        component: "CatalogSuppliers",
        icon: "mdi-store",
      },
      // {
      //   id: "membership",
      //   name: "",
      //   component: "CatalogItems",
      //   icon: "mdi-information",
      // },
    ],
  }),
  methods: {
    vibrate() {
      if (navigator.vibrate) navigator.vibrate(50);
    },
  },
  computed: {},
  watch: {},

  mounted() {},
};
</script>

<style></style>

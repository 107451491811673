<template>
  <v-card>
    <v-card-title>
      Criar Promoção
      <v-spacer />
      <v-btn icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-alert v-if="error" type="error" class="mx-4">
      {{ error }}
    </v-alert>
    <template v-if="stage == 0">
      <v-card-text>
        <v-text-field
          outlined
          label="Nome"
          v-model="name"
          :rules="[
            (v) => !!v || 'Nome é obrigatório',
            (v) =>
              (v && v.length <= 50) || 'Nome deve ter no máximo 50 caracteres',
          ]"
        />
        <v-row>
          <v-col v-for="(typ, index) in types" :key="index" cols="6" sm="4">
            <v-card
              outlined
              :class="type != typ.component ? '' : 'grey'"
              class="cursor-pointer"
              @click="selectType(typ.component)"
            >
              <v-card-title class="justify-center">
                <v-icon>{{ typ.icon }}</v-icon>
              </v-card-title>
              <v-card-text class="text-center">
                <h6>{{ typ.name }}</h6>
                {{ typ.description }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="next" :disabled="type == null || !name">
          Próximo
        </v-btn>
      </v-card-actions>
    </template>
    <component
      v-else
      :is="type"
      :loading="loading"
      @back="stage = 0"
      @save="save"
    />
  </v-card>
</template>

<script>
import PROMOTION from "@/services/admin/promotion";
import { mapGetters } from "vuex";

import BoughtWon from "@/components/admin/promotion/CreateBoughtWon.vue";
import Discount from "@/components/admin/promotion/CreateDiscount.vue";

export default {
  components: { bought_won: BoughtWon, discount: Discount },
  data: () => ({
    types: [
      {
        component: "bought_won",
        name: "Compre e Ganhe",
        description: "Compre um ingresso e ganhe outro",
        icon: "mdi-cart-plus",
      },
      {
        component: "discount",
        name: "Desconto",
        description: "Defina um desconto para um ingresso",
        icon: "mdi-percent",
      },
    ],
    error: false,
    loading: false,
    name: "",
    type: null,
    stage: 0,
  }),
  methods: {
    async save(data) {
      try {
        this.loading = true;
        this.error = false;
        
        await PROMOTION.create(this.selectedOrganization.id, {
          name: this.name,
          type: this.type,
          data,
        });
        this.$router.push({ name: "admin.promotions" });
      } catch (e) {
        this.error = e.message || "Erro ao criar promoção";
        this.loading = false;
      }
    },
    close() {
      const confirm = window.confirm("Deseja realmente sair?");
      if (confirm) this.$router.push({ name: "admin.promotions" });
    },
    selectType(type) {
      this.type = type;
    },
    next() {
      if (this.type == null || !this.name) return;
      this.stage = 1;
    },
  },
  watch: {
    "selectedOrganization.id"() {
      this.$router.push({ name: "admin.promotions" });
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
};
</script>

<style></style>

<template>
  <v-dialog v-model="dialog" width="800" scrollable content-class="rounded-xl">
    <v-card :loading="loading" class="rounded-xl">
      <v-card-text class="pa-2">
        <v-alert v-if="error" type="error" text>
          {{ error }}
        </v-alert>

        <v-row class="mx-0">
          <v-col cols="12" sm="6" md="7" lg="8">
            <div class="d-flex flex-column gap-2 h-full">
              <div class="d-flex gap-4 align-center">
                <div>
                  <v-img
                    height="50px"
                    width="50px"
                    class="rounded-lg"
                    :aspect-ratio="1"
                    :src="supplier?.image"
                  />
                </div>
                <div class="d-flex flex-column">
                  <span
                    v-if="!supplier.premium"
                    class="text-overline"
                    style="line-height: 0.5rem"
                  >
                    Fornecedor
                  </span>
                  <v-chip v-else x-small color="warning" text-color="white">
                    <v-icon x-small left>mdi-hexagram</v-icon>
                    Fornecedor Premium
                  </v-chip>

                  <h4 class="mb-0">{{ supplier?.name }}</h4>
                </div>
                <v-spacer />
                <div>
                  <v-btn v-if="supplier?.website" icon @click="openWebsite">
                    <v-icon>mdi-web</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="supplier?.ddi && supplier?.phone"
                    icon
                    @click="openWhatsapp(supplier)"
                  >
                    <v-icon>mdi-whatsapp</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="supplier?.instagram"
                    icon
                    @click="openInstagram(supplier)"
                  >
                    <v-icon>mdi-instagram</v-icon>
                  </v-btn>
                </div>
              </div>
              <p class="mb-0 pl-2">{{ supplier?.description }}</p>

              <div class="flex-grow-1 d-flex flex-column gap-2">
                <div
                  v-if="supplier?.Coupons && supplier?.Coupons.length"
                  class="d-flex flex-column"
                >
                  <h6 class="mb-0">Coupons de desconto</h6>

                  <supplier-cupom
                    :cupom="cupom"
                    v-for="(cupom, i) in supplier?.Coupons"
                    :key="i"
                    :supplier="supplier"
                    @copy="registerInterest('COUPON')"
                  />
                </div>
                <v-card
                  v-if="itemHighlight.length"
                  elevation="0"
                  outlined
                  class="d-flex flex-column pa-2 rounded-lg"
                >
                  <div
                    v-for="(highlight, i) in itemHighlight"
                    :key="i"
                    class="d-flex gap-2"
                  >
                    <v-icon color="primary">mdi-check</v-icon>
                    <span>{{ highlight }}</span>
                  </div>
                </v-card>
              </div>
              <div class="flex-grow-0 d-flex align-end">
                <v-btn small text @click="close"> Fechar </v-btn>
                <v-spacer />
                <div
                  class="flex-column d-flex align-end flex-grow-0 flex-shrink-1"
                  v-if="supplier?.requestClientData"
                >
                  <template v-if="!alreadyShared">
                    <div>
                      <v-checkbox
                        v-model="allowShareData"
                        class="mt-0 mb-1"
                        hide-details
                      >
                        <template v-slot:label>
                          <small>
                            Conceder ao fornecedor meu <br /><b>
                              nome e telefone
                            </b>
                            para contato.
                          </small>
                        </template>
                      </v-checkbox>
                    </div>
                    <v-btn
                      small
                      color="primary"
                      :disabled="!allowShareData"
                      :loading="loadingShareData"
                      @click="userShareData"
                    >
                      Solicitar contato
                    </v-btn>
                    <small v-if="errorShareData" class="text-end error--text">
                      {{ errorShareData }}
                    </small>
                  </template>
                  <template v-else>
                    <v-btn small color="primary" disabled>
                      Contato solicitado
                    </v-btn>
                    <small class="text-end">
                      Fornecedor já recebeu seu nome e telefone e entrará em
                      contato.
                    </small>
                  </template>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="5" lg="4">
            <v-card
              v-if="item"
              outlined
              elevation="0"
              class="pa-5 rounded-xl h-full"
            >
              <div class="d-flex flex-column">
                <span class="text-overline" style="line-height: 0.5rem">
                  <template v-if="item?.Item?.type === 'PRODUCT'">
                    Produto
                  </template>
                  <template v-else-if="item?.Item?.type === 'SERVICE'">
                    Serviço
                  </template>
                </span>
                <h5 class="mb-0">{{ item.Item?.name }}</h5>
              </div>
              <div>
                <v-img
                  :aspect-ratio="1"
                  :src="item.image || item.Item?.image"
                  class="rounded-lg"
                />
              </div>
              <v-btn
                block
                text
                small
                class="mt-1"
                @click="item = null"
                v-if="itens.length > 1"
              >
                Ver outros produtos
              </v-btn>
            </v-card>
            <div v-else>
              <p class="mb-0 font-weight-bold">Produtos</p>
              <vue-perfect-scrollbar
                :settings="{ suppressScrollX: true, wheelPropagation: false }"
                style="height: 100%; max-height: 400px"
              >
                <cols-organizer :items="itens" :cols="2" :gap="1">
                  <template v-slot:default="{ item: itm }">
                    <v-card
                      @click="item = itm"
                      class="pa-2 rounded-lg"
                      elevation="0"
                      outlined
                    >
                      <v-img
                        :aspect-ratio="1"
                        :src="itm.image || itm.Item.image"
                        class="rounded-lg"
                      />
                      <h6 class="mb-0 px-1 text-12">{{ itm.Item.name }}</h6>
                    </v-card>
                  </template>
                </cols-organizer>
              </vue-perfect-scrollbar>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CATALOG from "@/services/admin/extras/catalog";
import SupplierCard from "../SupplierCard.vue";
import ColsOrganizer from "../../../global/ColsOrganizer.vue";
import SupplierCupom from "../SupplierCupom.vue";
export default {
  components: {
    SupplierCard,
    ColsOrganizer,
    SupplierCupom,
  },
  data: () => ({
    dialog: false,
    loading: false,
    loadingShareData: false,
    error: false,
    errorShareData: false,
    item: null,
    itens: [],
    supplier: {},
    dataShared: false,
    allowShareData: false,
  }),

  methods: {
    async userShareData() {
      try {
        this.loadingShareData = true;
        this.errorShareData = false;
        await this.registerInterest("SHARE_CONTACT", false);
        this.dataShared = true;
      } catch (e) {
        this.errorShareData = e.message || "Erro ao solicitar orçamento";
        setTimeout(() => {
          this.errorShareData = false;
        }, 5000);
      } finally {
        this.loadingShareData = false;
      }
    },
    openInstagram(supplier) {
      this.registerInterest("INSTAGRAM");
      window.open(`https://instagram.com/${supplier.instagram}`, "_blank");
    },
    openWebsite() {
      this.registerInterest("WEBSITE");
      window.open(this.supplier.website, "_blank");
    },
    openWhatsapp(supplier) {
      this.registerInterest("WHATSAPP");
      window.open(
        `https://api.whatsapp.com/send?phone=${
          supplier.ddi
        }${supplier.phone.replace(/[^0-9]/g, "")}`,
        "_blank"
      );
    },

    async registerInterest(type, catchError = true) {
      try {
        await CATALOG.interest.register({
          supplierId: this.supplier.id,
          itemId: this.item?.id,
          type,
        });
        this.$root.$emit("catalog-interest", {
          supplier: this.supplier,
          item: this.item,
        });
      } catch (e) {
        if (catchError) return console.log(e);
        throw e;
      }
    },

    async getSupplierDetails() {
      try {
        this.loading = true;
        this.error = false;
        const {
          supplier: { Itens, ...supplier },
        } = await CATALOG.supplier.getById(this.supplier.id);
        this.itens = Itens;
        this.supplier = supplier;
        if (this.item?.id)
          this.item = this.itens.find((item) => item.id === this.item.id);
        else if (this.itens.length === 1) this.item = this.itens[0];
      } catch (e) {
        this.error = e.message || "Erro ao carregar detalhes do item";
      } finally {
        this.loading = false;
      }
    },
    open({ supplier, item }) {
      this.supplier = Object.assign({}, supplier);
      this.item = item ? Object.assign({}, item) : null;
      this.dataShared = false;
      this.allowShareData = false;
      this.getSupplierDetails();
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.item = null;
      this.itens = [];
      this.supplier = {};
      this.loading = false;
      this.loadingShareData = false;
      this.dataShared = false;
      this.allowShareData = false;
      this.error = false;
      this.errorShareData = false;
    },
  },
  computed: {
    itemHighlight() {
      if (!this.item?.highlight) return [];
      return this.item?.highlight
        .trim()
        .split("\n")
        .filter((h) => !!h);
    },
    alreadyShared() {
      return (
        this.dataShared ||
        (this.supplier?.Interest || []).find(
          (interest) => interest.type === "SHARE_CONTACT"
        )
      );
    },
  },
  mounted() {
    this.$root.$on("catalog-supplier", this.open);
  },
};
</script>

<style></style>

import http from "@/http-common";

const getAll = async (orgId) => {
  return await http
    .get(`/admin/organization/${orgId}/promotion`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getById = async (orgId, id) => {
  return await http
    .get(`/admin/organization/${orgId}/promotion/${id}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const create = async (orgId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/promotion`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  getById,
  getAll,
  create,
};

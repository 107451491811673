<template>
  <v-card
    class="pa-4 d-flex flex-column gap-2 rounded-lg"
    @click="$emit('click')"
    outlined
  >
    <div class="d-flex align-center gap-2">
      <div>
        <v-img
          :src="supplier.Supplier.image"
          :aspect-ratio="1"
          class="rounded-lg"
          height="35px"
          width="35px"
        />
      </div>
      <div class="d-flex flex-column">
        <h6 class="mb-0">
          {{ supplier.Supplier.name }}
        </h6>
        <small v-if="supplier.Supplier.Address" style="line-height: 0.7rem">
          {{ supplier.Supplier.Address.city }} /
          {{ supplier.Supplier.Address.state }}
        </small>
      </div>
      <v-spacer />
      <v-chip
        v-if="supplier.Supplier.premium"
        x-small
        color="warning"
        text-color="white"
      >
        <v-icon x-small left>mdi-hexagram</v-icon>
        Premium
      </v-chip>
    </div>
    <!-- <v-divider /> -->
    <div class="d-flex flex-column gap-2" v-if="highlights.length">
      <div v-for="(highlight, i) in highlights" :key="i" class="d-flex gap-2">
        <v-icon color="primary">mdi-check</v-icon>
        <span>{{ highlight }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  computed: {
    highlights() {
      const highlight = this.supplier?.highlight || "";
      return highlight
        .trim()
        .split("\n")
        .filter((h) => !!h);
    },
  },

  props: { supplier: { type: Object, required: true } },
};
</script>

<style></style>

<template>
  <div class="mt-3">
    <v-progress-linear indeterminate v-if="loading" />
    <v-alert type="error" v-if="error">
      {{ error }}
    </v-alert>

    <v-card
      class="rounded-lg pa-3"
      outlined
      elevation="0"
      v-if="suppliers.length"
    >
      <h6 class="mb-0 text-14 font-weight-medium">Fornecedores recentes</h6>
      <vue-perfect-scrollbar
        :settings="{
          suppressScrollY: false,
          wheelPropagation: false,
        }"
        class="w-full rounded-lg"
      >
        <div>
          <div class="d-flex gap-3">
            <div v-for="(item, i) in suppliers" :key="i" class="pt-2">
              <v-badge
                color="warning"
                icon="mdi-hexagram"
                overlap
                :value="item.premium"
              >
                <v-avatar
                  @click="openSupplier(item)"
                  size="60"
                  class="rounded-lg"
                  color="white"
                  style="cursor: pointer"
                >
                  <v-img
                    height="60"
                    width="60"
                    class="rounded"
                    :aspect-ratio="1"
                    :src="item.image"
                  />
                </v-avatar>
              </v-badge>
            </div>
          </div>
        </div>
      </vue-perfect-scrollbar>
    </v-card>

    <div class="d-flex align-center gap-3 mb-4 flex-wrap mt-2">
      <v-text-field
        hide-details
        v-model="search"
        label="Pesquisar"
        outlined
        dense
      />
      <v-chip-group v-model="filter" mandatory>
        <v-chip
          v-for="(item, i) in filterItens"
          :key="i"
          :value="item.value"
          filter
        >
          {{ item.text }}
        </v-chip>
      </v-chip-group>
    </div>

    <cols-organizer :items="filteredItens" :cols="2" :sm="4" :md="3" :lg="4">
      <template v-slot="{ item }">
        <v-card
          elevation="0"
          outlined
          class="rounded-lg"
          style="position: relative; cursor: pointer"
          @click="open(item)"
        >
          <v-img :aspect-ratio="1" :src="item.image" class="rounded-lg rounded-b-0" />
          <v-chip
            label
            x-small
            :color="item.type === 'PRODUCT' ? 'primary' : 'warning'"
            style="position: absolute; top: 10px; left: 10px"
          >
            {{ item.type === "PRODUCT" ? "Produto" : "Serviço" }}
          </v-chip>
          <div class="pa-2 px-4">
            <h6 class="mb-0 font-weight-medium">{{ item.name }}</h6>
            <!-- <small>{{ item._count.Suppliers }} fornecedor{{ item._count.Suppliers == 1 ? '': 'es' }}</small> -->
          </div>
        </v-card>
      </template>
    </cols-organizer>
  </div>
</template>

<script>
import CATALOG from "@/services/admin/extras/catalog";
import ColsOrganizer from "@/components/global/ColsOrganizer.vue";

export default {
  components: { ColsOrganizer },
  data: () => ({
    loading: false,
    error: false,
    itens: [],
    suppliers: [],
    search: "",
    filter: null,
    filterItens: [
      { text: "Todos", value: null },
      { text: "Produto", value: "PRODUCT" },
      { text: "Serviço", value: "SERVICE" },
    ],
  }),
  methods: {
    open(item) {
      this.$root.$emit("catalog-item", item);
    },
    openSupplier(supplier) {
      let item;
      if (
        supplier.Interest &&
        supplier.Interest.length &&
        supplier.Interest[0].Item
      ) {
        const { Item, ...irest } = supplier.Interest[0];
        item = { ...Item, Item: irest };
      }

      this.$root.$emit("catalog-supplier", {
        supplier,
        item,
      });
    },
    async getItens() {
      try {
        this.loading = true;
        this.error = false;
        const { itens, suppliers } = await CATALOG.item.getAll();
        this.itens = itens;
        this.suppliers = suppliers;
      } catch (e) {
        this.error = e.message || "Erro ao carregar itens do catálogo";
      } finally {
        this.loading = false;
      }
    },
    handleCatalogInterest({ supplier }) {
      if (!supplier) return;
      if (this.suppliers.find((s) => s.id === supplier.id)) return;
      this.suppliers.unshift(supplier);
    },
  },
  computed: {
    filteredItens() {
      return this.itens.filter((i) => {
        if (this.filter && i.type !== this.filter) return false;
        return i.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  mounted() {
    this.getItens();
    this.$root.$on("catalog-interest", this.handleCatalogInterest);
  },
};
</script>

<style></style>

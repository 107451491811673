<template>
  <div>
    <v-card :loading="loading">
      <v-card-text>
        <v-alert v-if="error" type="error">
          {{ error }}
        </v-alert>
        <template v-if="promotion">
          <div class="d-flex">
            <h5 class="mb-0">{{ promotion.name }}</h5>
            <v-chip label small class="ml-2">
              {{ types[promotion.type].name }}
            </v-chip>
            <v-spacer />
            <!-- <v-switch
              v-model="promotion.active"
              :disabled="loading"
              label="Ativo"
              color="primary"
              class="mt-0 mb-0"
              hide-details=""
            /> -->
          </div>
        </template>
      </v-card-text>
    </v-card>
    <component
      v-if="promotion && types[promotion.type].component"
      :is="types[promotion.type].component"
      :promotion="promotion"
    />
  </div>
</template>

<script>
import PROMOTION from "@/services/admin/promotion";
import { mapGetters } from "vuex";
import BoughtWon from "@/components/admin/promotion/DetailsBoughtWon.vue";
import Discount from "@/components/admin/promotion/DetailsDiscount.vue";
export default {
  components: { BoughtWon, Discount },
  data: () => ({
    promotion: null,
    error: false,
    loading: false,
    types: {
      bought_won: {
        component: "BoughtWon",
        name: "Compre e Ganhe",
        description: "Compre um ingresso e ganhe outro",
        icon: "mdi-cart-plus",
      },
      discount: {
        component: "Discount",
        name: "Desconto",
        description: "Desconto de 50% no ingresso",
        icon: "mdi-percent",
      },
    },
  }),
  methods: {
    async getPromotion() {
      try {
        this.loading = true;
        this.error = false;
        const { promotion } = await PROMOTION.getById(
          this.selectedOrganization.id,
          this.$route.params.id
        );
        this.promotion = promotion;
      } catch (error) {
        this.error = err.message || err;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    "selectedOrganization.id"() {
      this.$router.push({ name: "admin.promotions" });
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.getPromotion();
  },
};
</script>

<style></style>

<template>
  <v-card elevation="0">
    <v-alert v-if="error" type="error">
      {{ error }}
    </v-alert>
    <v-card-text v-else class="">
      <v-data-table
        :headers="headers"
        :items="promotions"
        :loading="loading"
        dense
      >
        <template v-slot:top>
          <div class="d-flex pa-2">
            <v-spacer />
            <v-btn
              color="primary"
              :to="{ name: 'admin.promotions.create' }"
              :disabled="loading"
              class="ml-2"
              :small="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon :small="$vuetify.breakpoint.mdAndDown"> mdi-plus </v-icon>
              promoção
            </v-btn>
          </div>
        </template>
        <template v-slot:item.type="{ item }">
          <v-chip label small>
            {{ type[item.type] }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            icon
            small
            :to="{ name: 'admin.promotions.details', params: { id: item.id } }"
          >
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import PROMOTION from "@/services/admin/promotion";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    loading: false,
    error: false,
    promotions: [],
    type: {
      bought_won: "Compre e Ganhe",
      discount: "Desconto",
    },
    headers: [
      { text: "Nome", value: "name" },
      { text: "Tipo", value: "type", align: "center" },
      { text: "", value: "actions", align: "center" },
    ],
  }),
  methods: {
    async getPromotions() {
      this.loading = true;
      try {
        const response = await PROMOTION.getAll(this.selectedOrganization.id);
        this.promotions = response.promotions;
      } catch (error) {
        this.error = error.response.data.message;
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  watch: {
    "selectedOrganization.id"() {
      this.getPromotions();
    },
  },
  mounted() {
    this.getPromotions();
  },
};
</script>

import { render, staticRenderFns } from "./CostSimulator.vue?vue&type=template&id=03a87dba&scoped=true"
import script from "./CostSimulator.vue?vue&type=script&lang=js"
export * from "./CostSimulator.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03a87dba",
  null
  
)

export default component.exports
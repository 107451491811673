<template>
  <div class="mt-3">
    <v-progress-linear indeterminate v-if="loading" />
    <v-alert type="error" v-if="error" class="mb-0">
      {{ error }}
    </v-alert>
    <template v-else>
      <v-text-field
        hide-details
        v-model="search"
        label="Pesquisar"
        outlined
        dense
        class="mb-4"
      />

      <cols-organizer
        :items="filteredSuppliers"
        :cols="1"
        :sm="2"
        :md="3"
        :lg="4"
      >
        <template v-slot="{ item }">
          <div class="d-flex flex-column align-center">
            <v-badge
              color="warning"
              icon="mdi-hexagram"
              overlap
              :value="item.premium"
              style="z-index: 1"
            >
              <v-avatar
                @click="open(item)"
                size="80"
                color="white"
                style="cursor: pointer"
                class="rounded-lg"
              >
                <v-img :aspect-ratio="1" :src="item.image" />
              </v-avatar>
            </v-badge>
            <v-card
              elevation="1"
              outlined
              class="rounded-xl pt-10 pa-4"
              style="
                position: relative;
                cursor: pointer;
                margin-top: -40px;
                width: 100%;
              "
              @click="open(item)"
            >
              <div
                class="d-flex flex-column align-center justify-space-between"
              >
                <h6 class="mb-0 font-weight-medium">{{ item.name }}</h6>
                <small v-if="item.Address">
                  {{ item.Address.city }} / {{ item.Address.state }}
                </small>
              </div>
              <v-card
                @click.stop
                outlined
                class="pa-2 rounded-lg mt-2"
                v-if="item.Itens.length"
              >
                <vue-perfect-scrollbar
                  :settings="{
                    suppressScrollY: false,
                    wheelPropagation: false,
                  }"
                  class="w-full"
                >
                  <div>
                    <div class="d-flex gap-2">
                      <div
                        v-for="itemm in item.Itens"
                        :key="itemm.id"
                        @click="openItem(item, itemm)"
                      >
                        <v-img
                          height="60"
                          width="60"
                          class="rounded"
                          :aspect-ratio="1"
                          :src="itemm.image || itemm.Item.image"
                        />
                      </div>
                    </div>
                  </div>
                </vue-perfect-scrollbar>
              </v-card>
            </v-card>
          </div>
        </template>
      </cols-organizer>
    </template>
  </div>
</template>

<script>
import CATALOG from "@/services/admin/extras/catalog";
import ColsOrganizer from "@/components/global/ColsOrganizer.vue";

export default {
  components: { ColsOrganizer },
  data: () => ({
    loading: false,
    error: false,
    suppliers: [],
    search: "",
  }),
  methods: {
    openItem(supplier, item) {
      this.$root.$emit("catalog-supplier", {
        supplier,
        item,
      });
    },
    open(supplier) {
      this.$root.$emit("catalog-supplier", {
        supplier,
      });
    },
    async getSuppliers() {
      try {
        this.loading = true;
        this.error = false;
        const { itens, suppliers } = await CATALOG.supplier.getAll();
        this.itens = itens;
        this.suppliers = suppliers;
      } catch (e) {
        this.error = e.message || "Erro ao carregar itens do catálogo";
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    filteredSuppliers() {
      return this.suppliers.filter((i) => {
        return i.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  mounted() {
    this.getSuppliers();
  },
};
</script>

<style></style>
